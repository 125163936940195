

export default [
  {
    path: "/",
    redirect: "/login",
    
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
    },
  },


  
 

];